import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Grid, GridCol } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import { Button } from '@naf/button';
import { ButtonLink } from '@naf/button-link';
import type { SanityBlock } from '@sanity/client';
import useSelector from '../../redux/typedHooks';
import { useWindowLocation } from '../../hooks/useWindowLocation';
import BlockContent from '../../components/block-content/BlockContent';
import S from '../../styles/articles/StyledArticle';
import { QuoteBlock } from '../../components/block-content/components/types/components/Quote';
import { AccordionBlock } from '../../components/block-content/components/types/components/Accordion';
import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import { LayoutWithMainContent } from '../../components/layout/Layout';
import { ProductArticleType } from '../../../../types/articleType';
import { isInternalLink } from '../../lib/isInternalLink';
import { useDocumentUrl } from '../../hooks/useDocumentUrl';
import BenefitsList from '../../components/benefits-list/BenefitsList';
import { useGTMDataLayer } from '../../hooks/useGTMDataLayer';
import { useResponsiveImage } from '../../hooks/useResponsiveImage';
import { MainEntryCard } from '../../components/entry-cards/MainEntryCard';
import { CampaignBlock } from '../../components/block-content/components/types/components/CampaignBlock';
import { getHostname } from '../../lib/getHostname';
import ResponsiveImage from '../../components/image/ResponsiveImage';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';
import { ButtonContainer } from './ProductArticle.styles';
import FeedbackSurvey from '../../components/feedbacksurvey/FeedbackSurvey';
import { EventPayload } from '../../../../types/GTM/EventPayload';
import { ThreeColumnBlock } from '../../components/block-content/components/types/components/ThreeColumnBlockSerializer';
import SocialProofBlock from '../../components/block-content/components/types/components/SocialProof';

interface Props {
  productArticle: ProductArticleType;
}

const ProductArticle = ({ productArticle }: Props) => {
  const history = useHistory();
  const baseUrl = useSelector((state) => state.application.baseUrl);
  const url = useDocumentUrl(productArticle?.slug);
  const location = useLocation();
  // Onload Tracking
  const dataLayer = useGTMDataLayer();
  const [isTrackingEventSent, setIsTrackingEventSent] = useState(false);

  useEffect(() => {
    if (productArticle.onloadTracking && dataLayer && !isTrackingEventSent) {
      const eventPayload: EventPayload = {
        event: productArticle.onloadTracking.event,
      };
      if (productArticle.onloadTracking.payloadReference === 'ecommerce' && productArticle.ecommerce) {
        eventPayload.ecommerce = productArticle.ecommerce;
      }
      dataLayer.push(eventPayload);
      setIsTrackingEventSent(true);
    }
  }, [productArticle, isTrackingEventSent, dataLayer]);

  const {
    name,
    header,
    body,
    image,
    relatedProduct,
    mainEntries,
    productSummary,
    threeColumn,
    quote,
    socialProof,
    campaignBlock,
    seoConfig,
    slug,
    metaDisplay,
  } = productArticle || {};

  const cldImage = useResponsiveImage({ imageId: image?.publicId, initialDimensions: { width: 1200, height: 630 } });
  const windowPath = useWindowLocation();
  const imageWrapperRef = useRef<HTMLDivElement>(null);
  const seoTitle = seoConfig?.title ? seoConfig.title : name;
  const schemaImage = cldImage && cldImage.toURL();

  const handleLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    urlString: string | undefined,
    text: string | undefined,
  ) => {
    e.preventDefault();

    // Handle ecommerce tracking events
    if (productArticle.relatedProduct?.ctaButtonLink?.tracking?.event) {
      const payload: EventPayload = { event: productArticle.relatedProduct?.ctaButtonLink?.tracking?.event };
      if (
        productArticle.relatedProduct?.ctaButtonLink?.tracking?.payloadReference === 'ecommerce' &&
        productArticle.ecommerce
      ) {
        payload.ecommerce = productArticle.ecommerce;
      }
      dataLayer?.push(payload);
    }

    // Handle external / internal links and click tracking
    if (urlString) {
      const { isInternal, relativeLink } = isInternalLink(baseUrl, urlString);
      if (isInternal) {
        dataLayer?.push({
          event: 'cta_click',
          cta_text: text,
          cta_location: location.pathname,
          cta_url: urlString,
          cta_hostname: getHostname(baseUrl),
          ctaPosition: 'content',
        });
        history.push(relativeLink);
      } else {
        const linkUrlObject = (urlString && new URL(urlString)) || undefined;
        dataLayer?.push({
          event: 'cta_click',
          cta_text: text,
          cta_location: location.pathname,
          cta_url: urlString,
          cta_hostname: linkUrlObject?.hostname,
          ctaPosition: 'content',
        });
        window.open(urlString);
      }
    }
  };

  return (
    <LayoutWithMainContent
      title={header || name}
      description={seoConfig?.introduction}
      imgUrl={schemaImage || ''}
      url={windowPath}
      gtmArgs={{
        page_type: 'ProductArticlePage',
        pageCategory: `${url}`,
        contentId: slug,
      }}
      isHiddenFromSearch={seoConfig?.isHiddenFromSearch}
      seoTitle={seoTitle}
      seoDescription={seoConfig?.introduction}
      internalSearchMetaData={{
        cardType: InternalSearchCardType.Product,
        product: { title: relatedProduct?.productHeaderText },
      }}
    >
      <S.Wrapper>
        <Grid>
          <GridCol s="12" m="12" l="12" xl="12">
            <BreadCrumb />
            {image && cldImage && (
              <S.ImageColWrapper ref={imageWrapperRef}>
                <ResponsiveImage imageId={image.publicId} altText={image.altText || image.caption} />
              </S.ImageColWrapper>
            )}
          </GridCol>
          <S.BodyCol s="12" m="12" l="8" xl="8" $hasImage={!!image}>
            <Text tag="h1" variant={TextVariant.Header1}>
              {header || name}
            </Text>
            {body && (
              <S.Body>
                <BlockContent value={body} />
              </S.Body>
            )}
            {relatedProduct && (
              <>
                {relatedProduct.productHeaderText && (
                  <S.NoMarginText tag="h3" variant={TextVariant.Header3}>
                    {relatedProduct.productHeaderText}
                  </S.NoMarginText>
                )}
                {relatedProduct.campaignText && (
                  <S.NoMarginText variant={TextVariant.Header3}>{relatedProduct.campaignText}</S.NoMarginText>
                )}
                {relatedProduct.productText && (
                  <S.RelatedProductText variant={TextVariant.ArticleText}>
                    {relatedProduct.productText}
                  </S.RelatedProductText>
                )}
                <ButtonContainer>
                  {relatedProduct.ctaButtonLink?.url && relatedProduct.ctaButtonLink?.text && (
                    <Button
                      href={relatedProduct.ctaButtonLink?.url}
                      onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                        handleLink(e, relatedProduct.ctaButtonLink?.url, relatedProduct.ctaButtonLink?.text)
                      }
                    >
                      {relatedProduct.ctaButtonLink?.text}
                    </Button>
                  )}
                  {relatedProduct.ctaSecondaryButtonLink?.url && relatedProduct.ctaSecondaryButtonLink?.text && (
                    <Button
                      href={relatedProduct.ctaSecondaryButtonLink?.url}
                      onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                        handleLink(
                          e,
                          relatedProduct.ctaSecondaryButtonLink?.url,
                          relatedProduct.ctaSecondaryButtonLink?.text,
                        )
                      }
                      variant="outline"
                    >
                      {relatedProduct.ctaSecondaryButtonLink?.text}
                    </Button>
                  )}
                </ButtonContainer>
              </>
            )}
          </S.BodyCol>
          {mainEntries && mainEntries?.length > 0 && (
            <S.SpacedGridRow>
              {mainEntries.map((entry) => (
                <MainEntryCard key={`${entry.title}`} entry={entry} col="4" />
              ))}
            </S.SpacedGridRow>
          )}
          {productSummary && productSummary.summarylist && productSummary.summarylist.length > 0 && (
            <S.SpacedGridRow>
              <GridCol s="12" m="12" l="8" xl="8">
                <S.ColoredBox>
                  {productSummary.header && (
                    <Text tag="h2" variant={TextVariant.Header2}>
                      {productSummary.header}
                    </Text>
                  )}
                  {productSummary.summarylist.map((accordion: { text: SanityBlock[]; title: string; type: string }) => (
                    <AccordionBlock key={accordion.title} value={accordion} />
                  ))}
                </S.ColoredBox>
              </GridCol>
            </S.SpacedGridRow>
          )}
          {threeColumn && (
            <S.SpacedGridRow>
              <S.ThreeColumnCol s="12" m="12" l="12" xl="12">
                <ThreeColumnBlock node={threeColumn} />
              </S.ThreeColumnCol>
            </S.SpacedGridRow>
          )}
          {quote && quote.text && (
            <S.SpacedGridRow>
              <S.QuoteCol s="12" m="12" l="12" xl="12">
                <QuoteBlock value={quote} />
              </S.QuoteCol>
            </S.SpacedGridRow>
          )}
          {socialProof && socialProof.text && (
            <S.SpacedGridRow>
              <S.QuoteCol s="12" m="12" l="12" xl="12">
                <SocialProofBlock value={socialProof} />
              </S.QuoteCol>
            </S.SpacedGridRow>
          )}
          {campaignBlock && (
            <GridCol s="12" m="12" l="12" xl={campaignBlock.illustration ? '12' : '11'}>
              <CampaignBlock value={campaignBlock} />
            </GridCol>
          )}
          {productArticle.membershipBenefits && (
            <GridCol s="12" m="12" l="12" xl="12">
              <S.ReadMoreWrapper>
                <Text variant={TextVariant.Header2}>Medlemsfordeler</Text>
                <ButtonLink text="Se alle" onClick={() => history.push('/medlemskap/medlemsfordeler')} />
              </S.ReadMoreWrapper>
              <BenefitsList benefitItems={productArticle.membershipBenefits} />
            </GridCol>
          )}
          {!metaDisplay.hideForm && (
            <GridCol s="12" m="12" l="8" xl="8">
              <FeedbackSurvey contentUrl={windowPath} />
            </GridCol>
          )}
        </Grid>
      </S.Wrapper>
    </LayoutWithMainContent>
  );
};
export default ProductArticle;
